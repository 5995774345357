.contact__container {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    column-gap: 4rem;
    row-gap: 2rem;
    margin-bottom: var(--mb-2);
}

.contact__box {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    padding: 1rem 1.25rem;
}

.contact__icon {
    display: block;
    width: 4.5rem;
    margin-bottom: var(--mb-1);
    margin-top: 5rem;
}

.contact__title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-normal);
}

.footer {
    display: block;
    font-size: var(--small-font-size);
    margin-top: 5rem;
    text-align: center;
  }



/*=============== BREAKPOINTS ===============*/

  /* For large devices */
  @media screen and (max-width: 992px) {
  
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .contact__container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }
   
  }
  
  @media screen and (max-width: 576px) {
    .contact__container {
        gap: 1rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 540px) {
    .contact__container {
        grid-template-columns: repeat(1, 1fr);
    }
    
  }

   /* For small devices */
   @media screen and (max-width: 370px) {
    .contact__title {
        font-size: var(--h3-font-size);
        font-weight: var(--font-normal);
    }
  }
  
